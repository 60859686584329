import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    currentUser: User;
    // store the URL so we can redirect after logging in
    redirectUrl: string;

    constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {

    }

    //Postman -> login -> login
    login(userDetails) {
        const url = environment.apiUrl + "CtmsUsers/login";
        return this.http.post(url, userDetails);
    }


    getUserDetails() {
        var obj = JSON.parse(localStorage.getItem('ctms_user'));
        return this.http.get(environment.apiUrl + 'CtmsUsers/' + obj['userId']);
    }

    register(user_details) {
        const url = environment.apiUrl + "users/register";
        return this.http.post(url, user_details);
    }

    logout() {
        localStorage.clear();
        const url = environment.apiUrl + "CtmsUsers/logout";
        return this.http.post(url,"");
    }

    resetPassword(password, id) {
        const resetpswHeader = new HttpHeaders()
            .set('Authorization', `${id}`)
        const url = environment.apiUrl + "CtmsUsers/reset-password";
        return this.http.post(url, { newPassword: password }, { headers: resetpswHeader });
    }

    forgetPassword(email) {
        const url = environment.apiUrl + "CtmsUsers/reset";
        return this.http.post(url, email);
    }


    setPassword(userObj) {
        const url = environment.apiUrl + "users/setPassword";
        return this.http.post(url, userObj);

    }

    createerrorlogs(f) {
        const url = environment.apiUrl + "logs";
        return this.http.post(url, f);

    }

    getRoles() {
        var user = JSON.parse(localStorage.getItem('ctms_user'));
        let seconds = user.ttl / 1000;
        let minutes = seconds / 60;
        console.log(Math.floor(minutes));
        console.log(new Date());
        return this.http.get(environment.apiUrl + 'AppUsers/get-roles-for-user?userId=' + user.userId);
    }



}
