import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  user;
  constructor(private http: HttpClient) {

    this.user = JSON.parse(localStorage.getItem('ctms_user'));
   }

  //Postman -> Authenticated Route
  userAuthenticationforProject(route, projectId) {
    const url = environment.apiUrl + "Projects/authenticate-route?route=" + route + "&projectId=" + projectId;
    return this.http.get(url);
  }

  //Postman -> project -> create project
  createProject(ProjectDetails) {
    const url = environment.apiUrl + "Projects";
    return this.http.post(url, ProjectDetails);
  }

  //Postman -> project -> create project
  getMenuForProject(projectId) {
    const url = environment.apiUrl + "Projects/get-role-menu-for-project?projectId=" + projectId;
    return this.http.get(url);
  }

  //Postman -> project -> get project
  getProjectList() {
    const header = new HttpHeaders()
      .set('filter', `{"include":["project_sponsor","project_site","project_protocol"]}`);
    const url = environment.apiUrl + "Projects";
    return this.http.get(url);
  }

  //Postman -> project -> Get Project Details by ProjectId
  getProjectDetailsByProjectId(projectId) {
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.get(url);
  }

  //Postman -> project -> Update Project
  updateProject(ProjectDetails, projectId) {
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.patch(url, ProjectDetails);
  }

  //Postman -> get project list for user -> get project list for user
  getProjectListForUsers() {
    const url = environment.apiUrl + "Projects/list-user-projects";
    return this.http.get(url);
  }

  //Postman ->Project Sponsor -> Secondary sponsor -> Assign secondary sponsor to project 
  assignSecondarySponsorToProject(projectId, sponsorArray) {
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.patch(url, sponsorArray);
  }

  //Postman -> Project Sponsor-> Primary Sponsor -> Assign Primary sponsor to project 
  assignPrimarySponsorToProject(projectId, sponsorId, sponsorDetails) {
    var primarySponsorData = { "primarySponsorId": sponsorDetails.id }
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.patch(url, primarySponsorData);
  }

  //Postman -> Project Sponsor-> Primary Sponsor -> get project  Primary sponsor 
  getPrimarySponsorForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"primarySponsor","scope":{"include":[{"relation":"sponsorCountry"},{"relation":"sponsorState"},{"relation":"sponsorCity"}]}}],"fields":{"primarySponsorId":true,"primarySponsor":true}}`)
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.get(url, { headers: header });
  }

  //Postman -> Project Sponsor-> Secondary Sponsor -> get project  Secondary sponsor 
  getSecondarySponsorForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"secondarySponsors","scope":{"include":[{"relation":"sponsorCountry"},{"relation":"sponsorState"},{"relation":"sponsorCity"}]}}],"fields":{"secondarySponsorList":true,"secondarySponsors":true}}`)
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.get(url, { headers: header });
  }

  //Postman -> Project Sponsor-> Secondary Sponsor -> Remove Secondary sponsor from project
  deleteSecondarySponsorForProject(projectId, sponsorId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/secondarySponsors/rel/" + sponsorId;
    return this.http.delete(url);
  }

  //Postman -> CRO Mapping -> Get CRO for Project
  getCroForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"cros","scope":{"include":[{"relation":"croCountry"},{"relation":"croState"},{"relation":"croCity"}]}}],"fields":{"croList":true}}`)
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.get(url, { headers: header });
  }

  //Postman -> CRO Mapping -> Assign CRO to project
  assignCroToProject(projectId, croIdArray) {
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.patch(url, croIdArray);
  }

  //Postman -> CRO Mapping -> Delete CRO for project
  deleteCroForProject(projectId, CroId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/cros/rel/" + CroId;
    return this.http.delete(url);
  }


  //Postman -> Country Mapping -> Get Country for Project
  getCountryForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"country"}],"fields":{"countryList":true}}`)
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.get(url, { headers: header });
  }

  //Postman -> Country Mapping  -> Assign Country to project
  assignCountryToProject(projectId, countryIdArray) {
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.patch(url, countryIdArray);
  }

  //Postman -> Country Mapping  -> Delete Country for project
  deleteCountryForProject(projectId, CountryId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/country/rel/" + CountryId;
    return this.http.delete(url);
  }


  //Postman -> Site Mapping -> Get Country for Fetching Site
  getCountryArrayForSites(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"fields":{"countryList":true}}`)
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.get(url, { headers: header });
  }

  //Postman -> Site Mapping -> Get Country for Fetching Site
  getSitesFormCountry(siteAndCountryArray) {
    const header = new HttpHeaders()
      // .set('filter', `{"where":{"countryId":{"inq":${JSON.stringify(countryArray)}}},"include":[{"relation":"siteCountry"},{"relation":"siteState"},{"relation":"siteCity"}]}`)
      .set('filter', `{"where":{"and": ${JSON.stringify(siteAndCountryArray)}},"include":[{"relation":"siteCountry"},{"relation":"siteState"},{"relation":"siteCity"}]}`)
    const url = environment.apiUrl + "SiteHospitals";
    return this.http.get(url, { headers: header });
  }

  //Postman -> Site Mapping  -> Assign Site to project
  assignSiteToProject(projectId, siteIdArray) {
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.patch(url, siteIdArray);
  }

  //Postman -> Site Mapping -> Get Site for Project
  getSiteForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"site","scope":{"include":[{"relation":"siteCountry"},{"relation":"siteState"},{"relation":"siteCity"}]}}],"fields":{"siteList":true}}`)
    const url = environment.apiUrl + "Projects/" + projectId;
    return this.http.get(url, { headers: header });
  }

  //Postman -> Site Mapping  -> Delete Site for project
  deleteSiteForProject(projectId, SiteId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/site/rel/" + SiteId;
    return this.http.delete(url);
  }

  //PM
  //Postman -> Role Mapping  -> PM -> Assign Pm User Role  For Project
  assignPmForProject(projectId, resourceId, countryArray) {
    let pmDetails = {
      "ctmsUserId": resourceId,
      "countryIds": countryArray
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/pmUsers";
    return this.http.post(url, pmDetails);
  }

  //Postman -> Role Mapping  -> PM -> Update Country List for PM
  updateCountryListForPM(projectId, pmUniqueId, countryArray) {
    const url = environment.apiUrl + "Projects/" + projectId + "/pmUsers/" + pmUniqueId;
    return this.http.put(url, countryArray);
  }

  //Postman -> Role Mapping  -> PM ->Get Assigned Pm List For Project
  getAssignedPmUsersForProject(projectId) {
    const header = new HttpHeaders()
    .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"countries"}]}`)

    const url = environment.apiUrl + "Projects/" + projectId + "/pmUsers";
    return this.http.get(url,{headers:header});
  }

  //Postman -> Role Mapping  -> PM -> Remove Particular Pm From Project
  removeAssignedPmFormProject(projectId, pmUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/pmUsers/" + pmUniqueId;
    return this.http.delete(url);
  }

  //CRA
  //Postman -> Role Mapping  -> CRA -> Get Assigned CRA List For Project
  getAssignedCRAUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"sites"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/craUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> CRA -> Assign CRA User Role  For Project
  assignCRAForProject(projectId, resourceId, siteArray) {
    let craDetails = {
      "ctmsUserId": resourceId,
      "siteIds": siteArray
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/craUsers";
    return this.http.post(url, craDetails);
  }

  //Postman -> Role Mapping  -> CRA -> Update Site List for CRA
  updateSiteListForCRA(projectId, craUniqueId, siteArray) {
    const url = environment.apiUrl + "Projects/" + projectId + "/craUsers/" + craUniqueId;
    return this.http.put(url, siteArray);
  }

  //Postman -> Role Mapping  -> CRA -> Remove Particular CRA From Project
  removeAssignedCRAFormProject(projectId, craUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/craUsers/" + craUniqueId;
    return this.http.delete(url);
  }

  //CTA  
  //Postman -> Role Mapping  -> CTA -> Get Assigned CTA List For Project
  getAssignedCTAUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"sites"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/ctaUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> CTA -> Assign CTA User Role  For Project
  assignCTAForProject(projectId, resourceId, siteArray) {
    let ctaDetails = {
      "ctmsUserId": resourceId,
      "siteIds": siteArray
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/ctaUsers";
    return this.http.post(url, ctaDetails);
  }

  //Postman -> Role Mapping  -> CTA -> Update Site List for CTA
  updateSiteListForCTA(projectId, ctaUniqueId, siteArray) {
    const url = environment.apiUrl + "Projects/" + projectId + "/ctaUsers/" + ctaUniqueId;
    return this.http.put(url, siteArray);
  }

  //Postman -> Role Mapping  -> CTA -> Remove Particular CTA From Project
  removeAssignedCTAFormProject(projectId, ctaUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/ctaUsers/" + ctaUniqueId;
    return this.http.delete(url);
  }


  //RR  
  //Postman -> Role Mapping  -> RR -> Get Assigned RR List For Project
  getAssignedRRUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"sites"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/rrUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> RR -> Assign RR User Role  For Project
  assignRRForProject(projectId, resourceId, siteArray) {
    let rrDetails = {
      "ctmsUserId": resourceId,
      "siteIds": siteArray
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/rrUsers";
    return this.http.post(url, rrDetails);
  }

  //Postman -> Role Mapping  -> RR -> Update Site List for RR
  updateSiteListForRR(projectId, rrUniqueId, siteArray) {
    const url = environment.apiUrl + "Projects/" + projectId + "/rrUsers/" + rrUniqueId;
    return this.http.put(url, siteArray);
  }

  //Postman -> Role Mapping  -> RR -> Remove Particular RR From Project
  removeAssignedRRFormProject(projectId, rrUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/rrUsers/" + rrUniqueId;
    return this.http.delete(url);
  }


  //MM  
  //Postman -> Role Mapping  -> MM -> Get Assigned MM List For Project
  getAssignedMMUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"countries"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/mmUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> MM -> Assign MM User Role  For Project
  assignMMForProject(projectId, resourceId, countryArray) {
    let mmDetails = {
      "ctmsUserId": resourceId,
      "countryIds": countryArray
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/mmUsers";
    return this.http.post(url, mmDetails);
  }

  //Postman -> Role Mapping  -> MM -> Update Country List for MM
  updateCountryListForMM(projectId, mmUniqueId, countryArray) {
    const url = environment.apiUrl + "Projects/" + projectId + "/mmUsers/" + mmUniqueId;
    return this.http.put(url, countryArray);
  }
  //Postman -> Role Mapping  -> MM -> Remove Particular MM From Project
  removeAssignedMMFormProject(projectId, mmUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/mmUsers/" + mmUniqueId;
    return this.http.delete(url);
  }


  //RA  
  //Postman -> Role Mapping  -> RA -> Get Assigned RA List For Project
  getAssignedRAUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"countries"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/raUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> RA -> Update Country List for RA
  updateCountryListForRA(projectId, raUniqueId, countryArray) {
    const url = environment.apiUrl + "Projects/" + projectId + "/raUsers/" + raUniqueId;
    return this.http.put(url, countryArray);
  }

  //Postman -> Role Mapping  -> RA -> Assign RA User Role  For Project
  assignRAForProject(projectId, resourceId, countryArray) {
    let raDetails = {
      "ctmsUserId": resourceId,
      "countryIds": countryArray
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/raUsers";
    return this.http.post(url, raDetails);
  }

  //Postman -> Role Mapping  -> RA -> Remove Particular RA From Project
  removeAssignedRAFormProject(projectId, raUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/raUsers/" + raUniqueId;
    return this.http.delete(url);
  }


  //PI  
  //Postman -> Role Mapping  -> PI -> Get Assigned PI List For Project
  getAssignedPIUsersForProject(projectId) {
    const header = new HttpHeaders()
    .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"site"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/piUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> PI -> Assign PI User Role  For Project
  assignPIForProject(projectId, resourceId, siteId) {
    let piDetails = {
      "ctmsUserId": resourceId,
      "siteId": siteId
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/piUsers";
    return this.http.post(url, piDetails);
  }

  //Postman -> Role Mapping  -> PI -> Remove Particular PI From Project
  removeAssignedPIFormProject(projectId, piUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/piUsers/" + piUniqueId;
    return this.http.delete(url);
  }

  //SI  
  //Postman -> Role Mapping  -> SI -> Get Assigned SI List For Project
  getAssignedSIUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"sites"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/siUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> SI -> Assign SI User Role  For Project
  assignSIForProject(projectId, resourceId, siteId) {
    let siDetails = {
      "ctmsUserId": resourceId,
      "siteId": siteId
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/siUsers";
    return this.http.post(url, siDetails);
  }

  //Postman -> Role Mapping  -> SI -> Remove Particular SI From Project
  removeAssignedSIFormProject(projectId, siUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/siUsers/" + siUniqueId;
    return this.http.delete(url);
  }

  //SC  
  //Postman -> Role Mapping  -> SC -> Get Assigned SC List For Project
  getAssignedSCUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"sites"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/scUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> SC -> Assign SC User Role  For Project
  assignSCForProject(projectId, resourceId, siteId) {
    let scDetails = {
      "ctmsUserId": resourceId,
      "siteId": siteId
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/scUsers";
    return this.http.post(url, scDetails);
  }

  //Postman -> Role Mapping  -> SC -> Remove Particular SC From Project
  removeAssignedSCFormProject(projectId, scUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/scUsers/" + scUniqueId;
    return this.http.delete(url);
  }

  //SN  
  //Postman -> Role Mapping  -> SN -> Get Assigned SN List For Project
  getAssignedSNUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"sites"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/snUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> SN -> Assign SN User Role  For Project
  assignSNForProject(projectId, resourceId, siteId) {
    let snDetails = {
      "ctmsUserId": resourceId,
      "siteId": siteId
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/snUsers";
    return this.http.post(url, snDetails);
  }

  //Postman -> Role Mapping  -> SN -> Remove Particular SN From Project
  removeAssignedSNFormProject(projectId, snUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/snUsers/" + snUniqueId;
    return this.http.delete(url);
  }

  //PH  
  //Postman -> Role Mapping  -> PH -> Get Assigned PH List For Project
  getAssignedPHUsersForProject(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"sites"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/phUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> PH -> Assign PH User Role  For Project
  assignPHForProject(projectId, resourceId, siteId) {
    let phDetails = {
      "ctmsUserId": resourceId,
      "siteId": siteId
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/phUsers";
    return this.http.post(url, phDetails);
  }

  //Postman -> Role Mapping  -> PH -> Remove Particular PH From Project
  removeAssignedPHFormProject(projectId, phUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/phUsers/" + phUniqueId;
    return this.http.delete(url);
  }


  //LA  
  //Postman -> Role Mapping  -> LA -> Get Assigned LA List For Project
  getAssignedLAUsersForProject(projectId) {
    const header = new HttpHeaders()
    .set('filter', `{"include":[{"relation":"ctmsUser","scope":{"fields":["firstName","email","croId","sponsorId","siteId","resourceTypeId"],"include":[{"relation":"user_cro","scope":{"fields":["croName"]}},{"relation":"user_sponsor","scope":{"fields":["sponsorName"]}},{"relation":"user_site","scope":{"fields":["siteName"]}},{"relation":"resourceType","scope":{"fields":["resourceName"]}}]}},{"relation":"sites"}]}`)
    const url = environment.apiUrl + "Projects/" + projectId + "/laUsers";
    return this.http.get(url,{ headers: header });
  }

  //Postman -> Role Mapping  -> LA -> Assign LA User Role  For Project
  assignLAForProject(projectId, resourceId, siteId) {
    let laDetails = {
      "ctmsUserId": resourceId,
      "siteId": siteId
    }
    const url = environment.apiUrl + "Projects/" + projectId + "/laUsers";
    return this.http.post(url, laDetails);
  }

  //Postman -> Role Mapping  -> LA -> Remove Particular LA From Project
  removeAssignedLAFormProject(projectId, laUniqueId) {
    const url = environment.apiUrl + "Projects/" + projectId + "/laUsers/" + laUniqueId;
    return this.http.delete(url);
  }

  //Postman -> PM Dashboard -> Get assigned Countries for PM

  getpmdashboarforpm(projectId) {
    let user = JSON.parse(localStorage.getItem('ctms_user'));
    const header = new HttpHeaders()
      .set('filter', `{"where":{"ctmsUserId":"${user.userId}"},"include":[{"relation":"countries","scope":{"include":[{"relation":"country_site","scope":{"fields":["id","siteName"]}}]}}]}`)
    const url = environment.apiUrl + "Projects/" + projectId +"/pmUsers";
    //const url = environment.apiUrl + "Projects/pm-user-list-with-country-and-site?projectId="+ projectId ;
    return this.http.get(url, { headers: header });
  }





}
