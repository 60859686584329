import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '../../../../node_modules/@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userToken: any = {};

  constructor(private router: Router) {

  }
  getUserDetails() {
    this.userToken = JSON.parse(localStorage.getItem('ctms_user'));
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.getUserDetails();
    if (req.url != environment.apiUrl + 'CtmsUsers/login') {
    if (req.url != environment.apiUrl + 'CtmsUsers/logout') {
      if (req.url != environment.apiUrl + 'CtmsUsers/reset-password') {
        if (req.url != environment.apiUrl + 'CtmsUsers/reset') {
          req = req.clone({
            setHeaders: {
              'Content-Type': 'application/json; charset=utf-8',
              'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
              'Pragma': 'no-cache',
              'Expires': '0',
              'Accept': 'application/json',
              'Authorization': this.userToken.id
            },
          });
        }
      }


      return next.handle(req)
        .pipe(
          tap(event => {
            if (event instanceof HttpResponse) {

            }
          }, error => {

            // if (error.error.error.statusCode === 401) {
            //   localStorage.clear();
            //   this.router.navigate(['/login']);
            // }
          })
        )

    }
    }

    return next.handle(req);
  }

}