import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SubjectVisitService } from 'src/app/shared/services/subject-visit.service';
import { MatAutocompleteSelectedEvent, MatOptionSelectionChange } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-subject-visit-settings',
  templateUrl: './subject-visit-settings.component.html',
  styleUrls: ['./subject-visit-settings.component.css']
})
export class SubjectVisitSettingsComponent implements OnInit {
  usersControl: FormControl = new FormControl();
  postsControl: FormControl = new FormControl();
  users: any[] = [];
  posts: any[] = [];
  proxyValue: any;
  actionList;
  settingsList;
  VCList = [];
  SVList = [];
  RDList = [];
  USList = [];
  EOTVList = [];
  EOSVList = [];
  FVList = [];
  SFVList = [];
  numberOfVisit = [];
  currentVisit;
  paragaraph = "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from deFinibusBonorumetMalorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three', 'Two', 'Three', 'Two', 'Three', 'Two', 'Three', 'Two', 'Three', 'Two', 'Three'];
  opt = ['One', 'Two', 'Three', 'Two', 'Three', 'Two', 'Three', 'Two', 'Three', 'Two', 'Three', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  projectId;
  constructor(private subjectVisitService: SubjectVisitService, private toastr: ToastrService, ) { }
  ngOnInit() {
    this.projectId = JSON.parse(localStorage.getItem('projectId'));
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.getVisitiSettings();
    this.getVisitActionByProject("");
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    // console.log("CHnages", filterValue, this.myControl.value);
    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  getVisitActionByProject(subjectVisitType) {
    this.subjectVisitService.getSubjectVisitActionsByProjectId(this.projectId, subjectVisitType).subscribe((res) => {
      console.log(res);
      this.actionList = res;
    })
  }
  getVisitiSettings() {
    this.subjectVisitService.getSubjectVisitSettingsByProjectId(this.projectId).subscribe((res) => {
      this.settingsList = res;
      this.settingsList.forEach((element, index) => {
        if (element.subjectVisitType == "SV") {
          if (element.actionIds.length !== 0) {
            this.SVList = element.actions;
          }
        } else if (element.subjectVisitType == "RD") {
          if (element.actionIds.length !== 0) {
            this.RDList = element.actions;
          }
        } else if (element.subjectVisitType == "US") {
          if (element.actionIds.length !== 0) {
            this.USList = element.actions;
          }
        } else if (element.subjectVisitType == "EOTV") {
          if (element.actionIds.length !== 0) {
            this.EOTVList = element.actions;
          }
        } else if (element.subjectVisitType == "EOSV") {
          if (element.actionIds.length !== 0) {
            this.EOSVList = element.actions;
          }
        } else if (element.subjectVisitType == "FV") {
          if (element.actionIds.length !== 0) {
            this.FVList = element.actions;
          }
        } else if (element.subjectVisitType == "SFV") {
          if (element.actionIds.length !== 0) {
            this.SFVList = element.actions;
          }
        } else if (element.subjectVisitType == "VC") {
          let n = 0;
          let finalCount;
          finalCount = element.totalSubjectvisitCount;
          console.log("acitions", element.visitCycleActions.length);
          if (element.visitCycleActions.length !== 0) {
            n = element.visitCycleActions.length;
            element.visitCycleActions.forEach(actions => {
              actions.visitCycleId = this.settingsList[index].id
              console.log("acitions", actions);
              this.numberOfVisit.push(actions);
            });
            console.log("1st list", this.numberOfVisit);
            finalCount = element.totalSubjectvisitCount - element.visitCycleActions.length;
          }
          for (var i = n + 1; i <= element.totalSubjectvisitCount; i++) {
            this.numberOfVisit.push({ "visitNumber": i, "actionIds": [], "visitCycleId": this.settingsList[index].id });
          }
          console.log("2nd list", this.numberOfVisit);
          if (element.visitCycleActions.length !== 0) {
            this.VCList = element.visitCycleActions;
          }
        }
      });
    });
  }
  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    console.log(event.option.value);
  }
  visitData(data, index) {
    console.log(data);
    this.VCList = [];
    this.currentVisit = data;
    if (!data.id) {
      console.log(this.currentVisit);
      console.log("Create Pananum");
      let datas = {
        "visitNumber": data.visitNumber,
        "subjectVisitType": "VC",
        "projectId": this.projectId,
        "actionIds": []
      }
      this.subjectVisitService.createSubjectNumberOfVisitbyId(data.visitCycleId, datas).subscribe((res) => {
        console.log(res);
        this.numberOfVisit[index].id = res['id'];
        console.log(this.numberOfVisit);
      })
    } else {
      if (data.actions) {
        this.VCList = data.actions;
      } else {
        this.VCList = [];
      }
    }
  }
  addSubjectVisitActions(event: MatAutocompleteSelectedEvent, subjectType) {
    let service;
    console.log("se", event.option.value, subjectType);
    this.myControl.setValue("");
    if (subjectType !== "VC") {
      this.settingsList.forEach((element, index) => {
        if (element.subjectVisitType == subjectType) {
          if (this.settingsList[index].actionIds.indexOf(event.option.value.id) == -1) {
            this.settingsList[index].actionIds.push(event.option.value.id);
            if (subjectType == "RD") {
              this.RDList.push(event.option.value);
            } else if (subjectType == "SV") {
              this.SVList.push(event.option.value);
            } else if (subjectType == "US") {
              this.USList.push(event.option.value);
            }
            else if (subjectType == "EOTV") {
              this.EOTVList.push(event.option.value);
            }
            else if (subjectType == "EOSV") {
              this.EOSVList.push(event.option.value);
            }
            else if (subjectType == "FV") {
              this.FVList.push(event.option.value);
            }
            else if (subjectType == "SFV") {
              this.SFVList.push(event.option.value);
            }
            service = this.subjectVisitService.updateSubjectVisitSettingsbyId(this.settingsList[index].id, this.settingsList[index].actionIds);
          } else {
            this.toastr.error("This " + event.option.value.action_name + " already exits");
          }
        }
      });
    } else {
      let dublicate = [];
      console.log(this.VCList);
      this.VCList.forEach(element => {
        console.log("Element", element)
        dublicate.push(element.id);
      });
      console.log(dublicate);
      console.log(dublicate.indexOf(event.option.value.id));
      if (dublicate.indexOf(event.option.value.id) == -1) {
        this.VCList.push(event.option.value);
        this.currentVisit.actionIds.push(event.option.value.id);
        service = this.subjectVisitService.updateSubjectNumberOfVisitbyId(this.currentVisit.visitCycleId, this.currentVisit.id, this.currentVisit.actionIds);
      } else {
        this.toastr.error("This " + event.option.value.action_name + " already exits");
      }
    }
    this.updateSettings(service);
    // if (subjectType == "VC") {
    //   this.VCList.push(event.option.value);
    // }
    // else if (subjectType == "SV") {
    //   this.settingsList.forEach((element, index) => {
    //     if (element.subjectVisitType == "SV") {
    //       console.log(this.settingsList[index].actionIds.indexOf(event.option.value.id));
    //       if (this.settingsList[index].actionIds.indexOf(event.option.value.id) == -1) {
    //         this.settingsList[index].actionIds.push(event.option.value.id);
    //         this.SVList.push(event.option.value);
    //         service = this.subjectVisitService.updateSubjectVisitSettingsbyId(this.settingsList[index].id, this.settingsList[index].actionIds);
    //       } else {
    //         this.toastr.error("This " + event.option.value.action_name + " already exits");
    //       }
    //     }
    //   });
    // } else if (subjectType == "RD") {
    //   this.settingsList.forEach((element, index) => {
    //     if (element.subjectVisitType == "RD") {
    //       console.log(this.settingsList[index].actionIds.indexOf(event.option.value.id));
    //       if (this.settingsList[index].actionIds.indexOf(event.option.value.id) == -1) {
    //         this.settingsList[index].actionIds.push(event.option.value.id);
    //         this.RDList.push(event.option.value);
    //         service = this.subjectVisitService.updateSubjectVisitSettingsbyId(this.settingsList[index].id, this.settingsList[index].actionIds);
    //       } else {
    //         this.toastr.error("This " + event.option.value.action_name + " already exits");
    //       }
    //     }
    //   });
    // }
  }
  remove(subjectType, actionIndex) {
    let service;
    this.settingsList.forEach((element, index) => {
      if (element.subjectVisitType == subjectType) {
        this.settingsList[index].actionIds.splice(actionIndex, 1);
        if (subjectType == "RD") {
          this.RDList.splice(actionIndex, 1);
        } else if (subjectType == "SV") {
          this.SVList.splice(actionIndex, 1);
        } else if (subjectType == "US") {
          this.USList.splice(actionIndex, 1);
        }
        else if (subjectType == "EOTV") {
          this.EOTVList.splice(actionIndex, 1);
        }
        else if (subjectType == "EOSV") {
          this.EOSVList.splice(actionIndex, 1);
        }
        else if (subjectType == "FV") {
          this.FVList.splice(actionIndex, 1);
        }
        else if (subjectType == "SFV") {
          this.SFVList.splice(actionIndex, 1);
        }
        service = this.subjectVisitService.updateSubjectVisitSettingsbyId(this.settingsList[index].id, this.settingsList[index].actionIds);
        this.updateSettings(service);
      }
    });
    // if (subjectType == "SV") {
    //   this.SVList.splice(actionIndex, 1);
    // } else if (subjectType == "RD") {
    //   this.settingsList.forEach((element, index) => {
    //     if (element.subjectVisitType == "RD") {
    //       this.settingsList[index].actionIds.splice(actionIndex, 1);
    //       this.RDList.splice(actionIndex, 1);
    //       service = this.subjectVisitService.updateSubjectVisitSettingsbyId(this.settingsList[index].id, this.settingsList[index].actionIds);
    //       this.updateSettings(service);
    //     }
    //   });
    // }
  }
  
  removeVC(subjectType, data, index) {
    this.VCList.splice(index, 1);
    this.currentVisit.actionIds.splice(index, 1);
    let service = this.subjectVisitService.updateSubjectNumberOfVisitbyId(this.currentVisit.visitCycleId, this.currentVisit.id, this.currentVisit.actionIds);
    this.updateSettings(service);
  }
  updateSettings(service) {
    if (service !== undefined) {
      service.subscribe((res) => {
        console.log(res);
      });
    }
  }
}