import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ROUTES } from 'src/app/layouts/sidebar/sidebar.component';
import { AuthService } from '../services/auth.service';
import { MenuService } from '../services/menu.service';
import { MatDialog } from '@angular/material';
import { SelectProjectComponent } from 'src/app/project-selection/select-project/select-project.component';
import { SelectProjectForInnerdashboardComponent } from './select-project-for-innerdashboard/select-project-for-innerdashboard.component';
const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    project;
    name: string;
    menu: Array<any> = [];
    breadcrumbList: Array<any> = [];

    @ViewChild('app-navbar-cmp') button: any;

    constructor(location: Location,
        private menuService: MenuService,
        private renderer: Renderer, 
        private authService: AuthService, 
        private element: ElementRef, 
        private router: Router, 
        private aRoute:ActivatedRoute,
        private dialog: MatDialog) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        console.log(this.router);
        console.log(this.aRoute);
        console.log(this.location);
        // Data:  { title: 'Company' } 


    }
    minimizeSidebar(){
      const body = document.getElementsByTagName('body')[0];

      if (misc.sidebar_mini_active === true) {
          body.classList.remove('sidebar-mini');
          misc.sidebar_mini_active = false;

      } else {
          setTimeout(function() {
              body.classList.add('sidebar-mini');

              misc.sidebar_mini_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }
    hideSidebar(){
      const body = document.getElementsByTagName('body')[0];
      const sidebar = document.getElementsByClassName('sidebar')[0];

      if (misc.hide_sidebar_active === true) {
          setTimeout(function() {
              body.classList.remove('hide-sidebar');
              misc.hide_sidebar_active = false;
          }, 300);
          setTimeout(function () {
              sidebar.classList.remove('animation');
          }, 600);
          sidebar.classList.add('animation');

      } else {
          setTimeout(function() {
            body.classList.add('hide-sidebar');
              // $('.sidebar').addClass('animation');
              misc.hide_sidebar_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }

    ngOnInit() {
        this.project = JSON.parse(localStorage.getItem('project_details'));
        this.menu = this.menuService.getMenu();
        this.aRoute.data.subscribe(data => console.log(data));
        // this.listenRouting();
        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          this.sidebarClose();

          const $layer = document.getElementsByClassName('close-layer')[0];
          if ($layer) {
            $layer.remove();
          }
        });
    }

    logout() {
        localStorage.clear();
          this.router.navigate(['/login']);
          // this.authService.logout().subscribe((res) => {
            // this.router.navigate(['/login']);
        // });
      }
    onResize(event) {
      if ($(window).width() > 991) {
        return false;
      }
      return true;
    }
    sidebarOpen() {
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function() {
            $toggle.classList.add('toggled');
        }, 430);

        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        }else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function() {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function() { //asign a function
          body.classList.remove('nav-open');
          this.mobile_menu_visible = 0;
          this.sidebarVisible = false;

          $layer.classList.remove('visible');
          setTimeout(function() {
              $layer.remove();
              $toggle.classList.remove('toggled');
          }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    };
    sidebarClose() {
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function() {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobile_menu_visible = 0;
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        // console.log(this.location);
        let titlee: any = this.location.prepareExternalUrl(this.location.path());
        // console.log(titlee);
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    if (subtitle === titlee) {
                        return this.listTitles[i].title +'->'+ this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }
    listenRouting() {
        let routerUrl: string, routerList: Array<any>, target: any;
        this.router.events.subscribe((router: any) => {
          routerUrl = router.urlAfterRedirects;
          if (routerUrl && typeof routerUrl === 'string') {
            // 初始化breadcrumb
            target = this.menu;
            this.breadcrumbList.length = 0;
            // 取得目前routing url用/區格, [0]=第一層, [1]=第二層 ...etc
            routerList = routerUrl.slice(1).split('/');
            routerList.forEach((router, index) => {
              // 找到這一層在menu的路徑和目前routing相同的路徑
              target = target.find(page => page.path.slice(2) === router);
              // 存到breadcrumbList到時後直接loop這個list就是麵包屑了
              this.breadcrumbList.push({
                name: target.name,
                // 第二層開始路由要加上前一層的routing, 用相對位置會造成routing錯誤
                path: (index === 0) ? target.path : `${this.breadcrumbList[index-1].path}/${target.path.slice(2)}`
              });
              
              // 下一層要比對的目標是這一層指定的子頁面
              if (index+1 !== routerList.length) {
                target = target.children;
              }
            });
    
            console.log(this.breadcrumbList);
          }
        });
      }

      openProjectSelection() {
        const dialogRef = this.dialog.open(SelectProjectForInnerdashboardComponent, {
            width: '1250px',
            data:"selectProject"
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }
}
