import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SubjectVisitSettingsComponent } from './settings/subject-visit-settings/subject-visit-settings.component';


export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'select-project',
    loadChildren: './project-selection/project-selection.module#ProjectSelectionModule'
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [

      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'sponsor',
        loadChildren: './org/sponsor/sponsor.module#SponsorModule'
      },
      {
        path: 'cro',
        loadChildren: './org/cro/cro.module#CroModule'
      },
      {
        path: 'site',
        loadChildren: './org/site/site.module#SiteModule'
      },
      {
        path: 'resource',
        loadChildren: './org/resource/resource.module#ResourceModule'
      },
      {
        path: 'projects',
        loadChildren: './org/project/project.module#ProjectModule'
      },
      {
        path: 'subject-visit-actions',
        loadChildren: './org/subject-visit-action/subject-visit-action.module#SubjectVisitActionModule'
      },
      {
        path: 'protocol',
        loadChildren: './org/protocol/protocol.module#ProtocolModule'
      },
      {
        path: 'agenda-sections',
        loadChildren: './org/agenda-section/agenda-section.module#AgendaSectionModule'
      },
      {
        path: 'category',
        loadChildren: './org/category/category.module#CategoryModule'
      },
      {
        path: 'questions',
        loadChildren: './org/questions/questions.module#QuestionsModule'
      },
      {
        path: 'resource-mapping/:projectId',
        loadChildren: './resource-mapping/resource-mapping.module#ResourceMappingModule'
      },
      {
        path: 'site-visit-tracker',
        loadChildren: './site-visit-tracker/site-visit-tracker.module#SiteVisitTrackerModule'
      },
      
      {
        path: 'action-items',
        loadChildren: './trackers/action-itmes/action-itmes.module#ActionItmesModule'
      },
      {
        path: 'deviation-tracker',
        loadChildren: './trackers/deviation-tracker/deviation-tracker.module#DeviationTrackerModule'
      },
      {
        path: 'ae-sae-tracker',
        loadChildren: './trackers/ae-sae-trackers/ae-sae-trackers.module#AESAETrackersModule'
      },
      {
        path: 'ethics-committee-tracker',
        loadChildren: './trackers/ethics-committee-tracker/ethics-committee-tracker.module#EthicsCommitteeTrackerModule'
      },
      {
        path: 'icf-tracker',
        loadChildren: './trackers/icf-tracker/icf-tracker.module#IcfTrackerModule'
      },
      {
        path: 'icf-tracker-global',
        loadChildren: './trackers/icf-tracker-global/icf-tracker-global.module#IcfTrackerGlobalModule'
      },
      {
        path: 'imp-tracker',
        loadChildren: './trackers/imp-tracker/imp-tracker.module#ImpTrackerModule'
      },
      {
        path: 'payment-tracker',
        loadChildren: './trackers/payment-tracker/payment-tracker.module#PaymentTrackerModule'
      },
      {
        path: 'sdv-tracker',
        loadChildren: './trackers/sdv-tracker/sdv-tracker.module#SdvTrackerModule'
      },
      {
        path: 'payment-settings',
        loadChildren: './pm-visit-settings/pm-visit-settings.module#PmVisitSettingsModule'
      },
      {
        path: 'visit-settings',
        loadChildren: './visit-settings-for-pm/visit-settings-for-pm.module#VisitSettingsForPmModule'
      },
      {
        path: 'subject-visit-settings',
        component:SubjectVisitSettingsComponent
      }

    ]
  }
];
