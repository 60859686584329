import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule, MatSlideToggleModule, MatTableModule, MatSnackBarModule, MatListModule, MatDividerModule, MatPaginatorModule, MatNativeDateModule, MatDatepickerModule, MatCheckboxModule, MatProgressSpinnerModule, MatProgressBarModule, MatExpansionModule, MatMenuModule, MatIconModule, MatRadioModule, MatDialogModule,  MatTooltipModule, MatToolbarModule, MatInputModule, MatRippleModule, MatAutocompleteModule, MatTabsModule, MatButtonToggleModule, MatSortModule, MatSidenavModule } from '../../../node_modules/@angular/material';
import { MatStepperModule, MatGridListModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import {MatSelectModule} from '@angular/material/select';









@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatRippleModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatIconModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatDividerModule,
    MatListModule,
    MatSnackBarModule,
    MatTableModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSortModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,  
    CKEditorModule,
    MatSidenavModule,
    MatSlideToggleModule,

   


  ],
  exports: [
    CommonModule,
    MatInputModule,
    MatRippleModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatIconModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatDividerModule,
    MatListModule,
    MatSnackBarModule,
    MatTableModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatGridListModule,
    MatSortModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatMenuModule,
    MatSidenavModule,
    MatSlideToggleModule


  ],
  declarations: []
})
export class MaterialModule { }
