import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { MatButtonModule } from '@angular/material';
import { MenuService } from '../services/menu.service';
import { MaterialModule } from '../../material/material.module';
import { SelectProjectComponent } from '../../project-selection/select-project/select-project.component';
// import { ProjectSelectionModule } from 'src/app/project-selection/project-selection.module';
import { ProjectSelectionModule} from '../../project-selection/project-selection.module';
import { SelectProjectForInnerdashboardComponent } from './select-project-for-innerdashboard/select-project-for-innerdashboard.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
    imports: [ RouterModule, CommonModule, MatButtonModule,MaterialModule,  FormsModule,
        ReactiveFormsModule, ],
    declarations: [ NavbarComponent, SelectProjectForInnerdashboardComponent ],
    entryComponents: [SelectProjectForInnerdashboardComponent],
    // entryComponents:[SelectProjectForInnerdashboardComponent],
    exports: [ NavbarComponent ],
    providers:[MenuService]
})

export class NavbarModule {}
