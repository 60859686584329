import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubjectVisitService {

  constructor(private http: HttpClient) { }

  // Postman -> Subject Tracker -> Create Subject

  createSubjectTracker(formData) {
    console.log("createSubjects"+formData.JSON);
    const url = environment.apiUrl + "Subjects";
    return this.http.post(url, formData);
  }

  // Postman -> Subject Tracker -> Get Subject

  getSubjectTracker() {
   
    const url = environment.apiUrl + "Subjects";
    return this.http.get(url);
  }

  getSubjectTracker2(siteId,projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"where":{"siteHospitalId":"${siteId}","projectId":"${projectId}"}}`)
    const url = environment.apiUrl + "Subjects";
    return this.http.get(url, { headers: header });
  }
  getSubjectSTracker() {
    const header = new HttpHeaders()
    .set('filter', `{"include":[{"relation":"siteHospital","scope":{"fields":["siteName","siteNo"]}}]}`)
    const url = environment.apiUrl + "Subjects";
    return this.http.get(url, { headers: header });
  }

  getSubjectBySiteId(siteId) {
    const header = new HttpHeaders()
      .set('filter', `{"where":{"siteHospitalId":"${siteId}"}}`)
    const url = environment.apiUrl + "Subjects";
    return this.http.get(url, { headers: header });
  }

  // Postman -> Subject Tracker -> Get Subject By Id

  getSubjectTrackerById(subjectId) {
    const url = environment.apiUrl + "Subjects/" + subjectId;
    return this.http.get(url);
  }

  // Postman ->  Subject Tracker -> Update Subject

  updateSubject(subjectId, data) {
    const url = environment.apiUrl + "Subjects/" + subjectId;
    return this.http.patch(url, data);
  }

  // Postman ->Subject Visit Action -> createSubjectActions
  createSubjectActions(formData) {
    const url = environment.apiUrl + "SubjectVisitActions";
    return this.http.post(url, formData);
  }

  // Postman ->Subject Visit Action -> getSubjectActions
  getSubjectActions(projectId) {
    const header = new HttpHeaders()
      .set('filter', `{"where":{"projectId":"${projectId}"}}`)
    const url = environment.apiUrl + "SubjectVisitActions";
    return this.http.get(url, { headers: header });
  }

  // Postman ->Subject Visit Action -> updateSubjectActions
  updateSubjectActions(formData, id) {

    const url = environment.apiUrl + "SubjectVisitActions/" + id;
    return this.http.patch(url, formData);
  }

  // Postman ->Subject Visit Settings -> get visit by action
  getSubjectVisitActionsByProjectId(projectId, subjectVisitType) {
    // "subjectVisitType":"${subjectVisitType}"
    const header = new HttpHeaders()
      .set('filter', `{"where":{"projectId":"${projectId}"}}`)
    const url = environment.apiUrl + "SubjectVisitActions";
    return this.http.get(url, { headers: header });
  }

  // Postman ->Subject Visit Settings -> get visit and action by project id
  getSubjectVisitSettingsByProjectId(projectId) {
    // "subjectVisitType":"${subjectVisitType}"
    const header = new HttpHeaders()
      .set('filter', `{"where":{"projectId":"${projectId}"},"include":[{"relation":"actions"},{"relation":"visitCycleActions"}]}`)
    const url = environment.apiUrl + "SubjectVisitSettings";
    return this.http.get(url, { headers: header });
  }

  // Postman ->Subject Visit Settings -> Add Action Item in Visit Settings by ID
  updateSubjectVisitSettingsbyId(settingId, data) {
    let Ids = {
      "actionIds": data
    }
    const url = environment.apiUrl + "SubjectVisitSettings/" + settingId;
    return this.http.patch(url, Ids);
  }
  
  // Postman ->Subject Visit Settings -> Update Action for Visit/Cycle
  updateSubjectNumberOfVisitbyId(subjectsettingId, visitId, data) {
    let Ids = {
      "actionIds": data
    }
    const url = environment.apiUrl + "SubjectVisitSettings/"+ subjectsettingId + "/visitCycleActions/" + visitId;
    return this.http.put(url, Ids);
  }


  // Postman ->Subject Visit Settings -> Add Action for Visit/Cycle
  createSubjectNumberOfVisitbyId(visitCycleId, data) {
    const url = environment.apiUrl + "SubjectVisitSettings/"+ visitCycleId + "/visitCycleActions";
    return this.http.post(url, data);
  }

 

  // Postman ->Subject Visit Settings -> get visit by action
  createSubjectVisitActionsByProjectId(actionDetails) {
    const url = environment.apiUrl + "SubjectVisitActions";
    return this.http.post(url, actionDetails);
  }


  // Postman ->Subject Tracker -> SubjectVistResult
  createSubjectVisitActionsValue(subjectId, actionDetails) {
    const url = environment.apiUrl + "Subjects/"+ subjectId +"/subjectVisitResult";
    return this.http.post(url, actionDetails);
  }

  // Postman ->Subject Tracker -> SubjectVistResult
  updateSubjectVisitActionsValue(subjectId, actionDetails) {
    const url = environment.apiUrl + "Subjects/"+ subjectId +"/subjectVisitResult/" + actionDetails.id;
    return this.http.put(url, actionDetails);
  }

  // Postman ->Subject Tracker -> SubjectVistResult
  getSubjectVisitActionsResults(subjectId) {
    const url = environment.apiUrl + "Subjects/"+ subjectId ;
    return this.http.get(url);
  }




}
