import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from 'src/app/shared/services/auth.service';

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
  },
  {
    path: '/cra-dashboard',
    title: 'CRA Dashboard',
    type: 'link',
    icontype: 'dashboard'
  },
  {
    path: '/site-visit-tracker/site-list',
    title: 'Visit Tracker',
    type: 'link',
    icontype: 'dashboard',
        children: [
          {path: '/siteId', title: 'Site List', ab:'B'},
          {path: 'grid', title: 'Grid System', ab:'GS'},
          {path: 'panels', title: 'Panels', ab:'P'},
          {path: 'sweet-alert', title: 'Sweet Alert', ab:'SA'},
          {path: 'notifications', title: 'Notifications', ab:'N'},
          {path: 'icons', title: 'Icons', ab:'I'},
          {path: 'typography', title: 'Typography', ab:'T'}
      ]
  },
  {
    path: "/site-visit-tracker/site-dashboard/5bc03119ceb7f800147c038f",
    title: 'List',
    type: 'link',
    icontype: 'dashboard'
  },
  // {
  //     path: '/components',
  //     title: 'Components',
  //     type: 'sub',
  //     icontype: 'apps',
  //     collapse: 'components',
  //     children: [
  //         {path: 'buttons', title: 'Buttons', ab:'B'},
  //         {path: 'grid', title: 'Grid System', ab:'GS'},
  //         {path: 'panels', title: 'Panels', ab:'P'},
  //         {path: 'sweet-alert', title: 'Sweet Alert', ab:'SA'},
  //         {path: 'notifications', title: 'Notifications', ab:'N'},
  //         {path: 'icons', title: 'Icons', ab:'I'},
  //         {path: 'typography', title: 'Typography', ab:'T'}
  //     ]
  // },{
  //     path: '/forms',
  //     title: 'Forms',
  //     type: 'sub',
  //     icontype: 'content_paste',
  //     collapse: 'forms',
  //     children: [
  //         {path: 'regular', title: 'Regular Forms', ab:'RF'},
  //         {path: 'extended', title: 'Extended Forms', ab:'EF'},
  //         {path: 'validation', title: 'Validation Forms', ab:'VF'},
  //         {path: 'wizard', title: 'Wizard', ab:'W'}
  //     ]
  // },{
  //     path: '/tables',
  //     title: 'Tables',
  //     type: 'sub',
  //     icontype: 'grid_on',
  //     collapse: 'tables',
  //     children: [
  //         {path: 'regular', title: 'Regular Tables', ab:'RT'},
  //         {path: 'extended', title: 'Extended Tables', ab:'ET'},
  //         {path: 'datatables.net', title: 'Datatables.net', ab:'DT'}
  //     ]
  // },{
  //     path: '/maps',
  //     title: 'Maps',
  //     type: 'sub',
  //     icontype: 'place',
  //     collapse: 'maps',
  //     children: [
  //         {path: 'google', title: 'Google Maps', ab:'GM'},
  //         {path: 'fullscreen', title: 'Full Screen Map', ab:'FSM'},
  //         {path: 'vector', title: 'Vector Map', ab:'VM'}
  //     ]
  // },{
  //     path: '/widgets',
  //     title: 'Widgets',
  //     type: 'link',
  //     icontype: 'widgets'

  // },{
  //     path: '/charts',
  //     title: 'Charts',
  //     type: 'link',
  //     icontype: 'timeline'

  // },{
  //     path: '/calendar',
  //     title: 'Calendar',
  //     type: 'link',
  //     icontype: 'date_range'
  // },{
  //     path: '/pages',
  //     title: 'Pages',
  //     type: 'sub',
  //     icontype: 'image',
  //     collapse: 'pages',
  //     children: [
  //         {path: 'pricing', title: 'Pricing', ab:'P'},
  //         {path: 'timeline', title: 'Timeline Page', ab:'TP'},
  //         {path: 'login', title: 'Login Page', ab:'LP'},
  //         {path: 'register', title: 'Register Page', ab:'RP'},
  //         {path: 'lock', title: 'Lock Screen Page', ab:'LSP'},
  //         {path: 'user', title: 'User Page', ab:'UP'}
  //     ]
  // }
];
@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  loggedUser;
  role;
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  adminMenu = [
    {
      path: '/dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/protocol/list',
      title: 'Protocol',
      type: 'link',
      icontype: 'assignment'
    },

    {
      title: 'ORG',
      type: 'sub',
      icontype: 'apps',
      collapse: 'org',
      children: [
        { path: '/sponsor/list', title: 'Sponsor', ab: 'SP' },
        { path: '/cro/list', title: 'CRO', ab: 'CR' },
        { path: '/site/list', title: 'Site', ab: 'ST' },
        { path: '/resource/list', title: 'Resource', ab: 'RE' }
      ]
    },
    {
      path: '/projects/list',
      title: 'Projects',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/agenda-sections',
      title: 'Agenda Sections',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/category/list',
      title: 'Category',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/questions',
      title: 'Questions',
      type: 'link',
      icontype: 'dashboard'
    },
  ];

  craMenu = [
    {
      path: '/cra-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'AE-SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' },
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    },
    // {
    //   path: '/subject-visit-tracker',
    //   title: 'Subject Visit Tracker',
    //   type: 'link',
    //   icontype: 'dashboard'
    // },
  ]

  pmMenu = [
    {
      path: '/pm-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    // {
    //   path: '/action-items',
    //   title: 'Issue Log',
    //   type: 'link',
    //   icontype: 'dashboard'
    // },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' },
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    },
    {
      path: '/subject-visit-settings',
      title: 'Subject Visit Settings',
      type: 'link',
      icontype: 'dashboard'
    },
    // {
    //   path: '/payment-settings',
    //   title: 'Payment Tracker Settings',
    //   type: 'link',
    //   icontype: 'dashboard'
    // },
  ]

  ctaMenu = [
    {
      path: '/cta-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }
  ]

  rrMenu = [
    {
      path: '/rr-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }

  ]

  mmMenu = [
    {
      path: '/mm-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }


  ]

  raMenu = [
    {
      path: '/ra-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }

  ]

  piMenu = [
    {
      path: '/pi-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }

  ]

  siMenu = [
    {
      path: '/si-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }

  ]

  scMenu = [
    {
      path: '/sc-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }
    

  ]

  snMenu = [
    {
      path: '/sn-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }

  ]

  phMenu = [
    {
      path: '/ph-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }

  ]

  laMenu = [
    {
      path: '/la-dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      title: 'Trackers',
      type: 'sub',
      icontype: 'apps',
      collapse: 'Trackers',
      children: [
        { path: '/action-items/list', title: 'action Items', ab: 'AI' },
        { path: '/deviation-tracker/list', title: 'Deviation Tracker', ab: 'DT' },
        { path: '/ae-sae-tracker/list', title: 'SAE Tracker', ab: 'ST' },
        { path: '/ethics-committee-tracker/list', title: 'Ethics Committee Tracker', ab: 'ET' },
        { path: '/imp-tracker/list', title: 'IMP Reconciliation Tracker', ab: 'IM' },
        { path: '/icf-tracker/list', title: 'ICF Tracker List', ab: 'IC' },
        { path: '/icf-tracker-global/list', title: 'Global ICF Tracker', ab: 'GI' },
        { path: '/payment-tracker/list', title: 'Payment Tracker', ab: 'PT' },
        { path: '/sdv-tracker/list', title: 'SDV Tracker', ab: 'SD' }
        // { path: '/subject-visit-tracker', title: 'Subject Visit Tracker', ab: 'ST' },
      ]
    }

  ]
  projectId;
  constructor(private authService: AuthService) {

  }

  ngOnInit() {
    this.loadUserDetails();

    this.role = JSON.parse(localStorage.getItem('ctms_role'));
    this.menuItems = [{
      path: '/dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/site-visit-tracker/site-list',
      title: 'Site Visit Tracker',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/resource-mapping',
      title: 'Mapping',
      type: 'link',
      icontype: 'dashboard'
    }]

    this.projectId = JSON.parse(localStorage.getItem('projectId'));
    this.role = JSON.parse(localStorage.getItem('ctms_role'));
    console.log("Role", this.role);
    if (this.role == "admin") {
      this.menuItems = this.adminMenu;
    } else if (this.role == "CTA") {
      this.menuItems = this.ctaMenu;
    } else if (this.role == "CRA") {
      this.menuItems = this.craMenu;
    } else if (this.role == "PM") {
      this.menuItems = this.pmMenu;
    } else if (this.role == "RR") {
      this.menuItems = this.rrMenu;
    } else if (this.role == "MM") {
      this.menuItems = this.mmMenu;
    } else if (this.role == "RA") {
      this.menuItems = this.raMenu;
    } else if (this.role == "PI") {
      this.menuItems = this.piMenu;
    } else if (this.role == "SI") {
      this.menuItems = this.siMenu;
    } else if (this.role == "SC") {
      this.menuItems = this.scMenu;
    } else if (this.role == "SN") {
      this.menuItems = this.snMenu;
    } else if (this.role == "PH") {
      this.menuItems = this.phMenu;
    } else if (this.role == "LA") {
      this.menuItems = this.laMenu;
    }
    else {
      //this.getMenuForUsers();
    }
  }

  loadUserDetails() {
    this.authService.getUserDetails().subscribe((res) => {
      this.loggedUser = res;
      localStorage.setItem('ctms_users_detils', JSON.stringify(this.loggedUser));
    });
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
}
