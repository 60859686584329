import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { Router } from '@angular/router';
import { DataTableFilterService } from 'src/app/shared/services/data-table-filter.service';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-select-project-for-innerdashboard',
  templateUrl: './select-project-for-innerdashboard.component.html',
  styleUrls: ['./select-project-for-innerdashboard.component.css']
})
export class SelectProjectForInnerdashboardComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource;
  tableLength;
  displayedColumns: string[] = ['protocol', 'projectName', 'projectNumber', 'role'];
  displayNoRecords;
  projectDetailsList;

  constructor(
    private tableFilter: DataTableFilterService,
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<SelectProjectForInnerdashboardComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.getProjectList();
  }

  getProjectList() {
    this.projectService.getProjectListForUsers().subscribe((response) => {
      this.projectDetailsList = response['projects'];
      this.tableLength = this.projectDetailsList.length;
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.projectDetailsList);
      this.tableFilter.dataTableData(this.dataSource);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  projectSelection(project) {
    console.log(project);
    localStorage.setItem('ctms_role', JSON.stringify(project.userRole));
    localStorage.setItem("projectId", JSON.stringify(project.id));
    localStorage.setItem("project_details", JSON.stringify(project));
    this.dialogRef.close();
    if (project.userRole == "PM") {
      this.router.navigate(['/pm-dashboard']);
      // location.reload(); 
    } else if (project.userRole == "CRA") {
      this.router.navigate(['/cra-dashboard']);
      // location.reload();   

    } else if (project.userRole == "CTA") {
      this.router.navigate(['/cta-dashboard']);
    } else if (project.userRole == "RR") {
      this.router.navigate(['/rr-dashboard']);
    } else if (project.userRole == "MM") {
      this.router.navigate(['/mm-dashboard']);
    } else if (project.userRole == "RA") {
      this.router.navigate(['/ra-dashboard']);
    } else if (project.userRole == "PI") {
      this.router.navigate(['/pi-dashboard']);
    } else if (project.userRole == "SI") {
      this.router.navigate(['/si-dashboard']);
    } else if (project.userRole == "SC") {
      this.router.navigate(['/sc-dashboard']);
    } else if (project.userRole == "SN") {
      this.router.navigate(['/sn-dashboard']);
    } else if (project.userRole == "PH") {
      this.router.navigate(['/ph-dashboard']);
    } else if (project.userRole == "LA") {
      this.router.navigate(['/la-dashboard']);
    }
  }

}

export interface PeriodicElement {
  protocol: string;
  projectName: string;
  projectNumber: string;
  sponsor: string;
  site: string;
}
