import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';


import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { AuthService } from './shared/services/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptor/auth.interceptor';
import { DataTableFilterService } from './shared/services/data-table-filter.service';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './material/material.module';
import { SidebarModule } from './layouts/sidebar/sidebar.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MenuService } from './shared/services/menu.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SubjectVisitSettingsComponent } from './settings/subject-visit-settings/subject-visit-settings.component';
import {BreadcrumbModule} from 'xng-breadcrumb';








@NgModule({
  imports: [
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    MaterialModule,
    FixedpluginModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    BrowserModule,
    BrowserAnimationsModule,




    ToastrModule.forRoot(
      {
        timeOut: 5000,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
      }
    )
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    SubjectVisitSettingsComponent,

  ],

  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DataTableFilterService,
    MenuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
